import React from "react";
import AboutHero from "../../components/AboutHero";

const About = () => {
  return (
    <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
      <AboutHero />
    </div>
  );
};

export default About;
